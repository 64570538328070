.amenity-popup ::-webkit-scrollbar {
  display: none; /* Chrome */
}

.amenity-popup {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  .is-scrolling {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  .modal-dialog {
    position: absolute;
    top: 93px;
    left: 280px;
    width: 280px;
    display: flex;
    flex-direction: column;
    height: calc(97vh - 100px);
    overflow: hidden;
  }
  
  .modal-content {
    border-radius: unset;
    border-top: none;
    border-left: none;
  }
  
  .modal-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 52px;
  }
  
  .modal-header .close {
    padding-top: 8px;
  }
  
  .modal-title {
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.03em;
    text-align: center;
    color: #737980;
    text-transform: uppercase;
  }
  
  .modal-body {
    padding: 0;
  
    .name-section {
      margin: 0;
      padding: 26px 30px 32px 30px;
      height: 118px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      transition: height 0.3s ease;
  
      &.expanded {
        height: 142px;
      }
  
      .name {
        width: 220px;
        height: 54px;
        gap: 6px;
  
        label {
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
          text-align: center;
          color: #212529;
          margin-bottom: 4px;
        }
  
        .form-control {
          height: 32px;
          border-radius: 3.2px;
        }
      }
    }
  
    .tour-section {
      margin: 0;
      padding: 26px 30px 32px 30px;
      height: 232px;
      gap: 54px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      transition: height 0.3s ease;
  
      &.expanded-textarea {
        height: 364px;
      }
  
      &.expanded-text {
        height: 252px;
      }
  
      .radio-group {
        width: 120px;
        height: 54px;
        gap: 6px;
  
        .main-label {
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
          text-align: center;
          color: #212529;
        }
  
        .form-check {
          padding-left: 22px;
        }
  
        .form-check-input {
          margin-top: 0;
        }
  
        .form-check-label {
          margin-left: 8px;
        }
  
        input[type=radio] {
          width: 16px;
          height: 16px;
        }
      }
  
      .form-label {
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #495057;
        margin-bottom: 4px;
      }
  
      .form-control {
        width: 220px;
      }
  
      textarea {
        width: 220px;
        height: 70px;
        resize: none;
      }
    }
  
    .icons-section {
      margin: 0;
      padding: 26px 30px 32px 30px;
      height: 290px;
  
      .form-label {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        color: #212529;
      }
  
      .icon-grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-row-gap: 10px;
        grid-column-gap: 8px;
        margin-left: -2px;
        margin-top: 12px;
  
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 36px;
          height: 36px;
          cursor: pointer;
  
          &:last-child {
            background-color: #EBEBEB;
            border-radius: 50%;
            opacity: 0.6;
  
            &:hover {
              opacity: 1;
            }
          }
  
          &:hover, &:last-child {
            background-color: #EBEBEB;
            border-radius: 50%;
          }
        }
  
        .selected {
          border: 1px solid black;
          border-radius: 50%;

          &:last-child {
            background-color: #888;
            border-radius: 50%;
            opacity: 1;
          }
        }
      }
    }
  
    .custom-icon-section {
      height: 234px;
      margin-bottom: 6px;
  
      .form-label {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        color: #737980;
        margin-top: -14px;
      }
  
      .form-text {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        color: #737980;
      }
  
      .warning {
        color: #DC3545;
      }
  
      .dnd-section {
        height: 212px;
        width: 212px;
        padding: 28px 24px;
        border-radius: 12px;
        border: 1px dashed #007BFF;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: #D9EAFF;
  
          button {
            background-color: #D9EAFF;
          }
        }
  
        &.file-dragged {
          background-color: #D9EAFF;
        }
  
        .preview-container {
          height: 190px;
          width: 170px;
          overflow: auto;
  
          &.text {
            overflow: unset;
          }
        }
  
        .preview-image {
          max-width: 100%;
          max-height: 100%;
        }
  
        button {
          background-color: #FFFFFF;
          color: #007BFF;
          margin-top: 16px;
          margin-bottom: -10px;
  
          &:hover {
            background-color: #007BFF;
            color: #FFFFFF;
          }
  
          &:focus {
            box-shadow: none;
          }
        }
      }
  
    }
  }
  
  .modal-footer {
    width: 280px;
    height: 52px;
    margin: 0;
    padding: 0;
  
    button {
      width: 100%;
      height: 100%;
      border-radius: unset;
      background-color: #007BFF;
      margin: 0;
      padding: 0;
  
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}

