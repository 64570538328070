/**
 * Do not edit directly
 * Generated on Mon, 17 Mar 2025 19:56:39 GMT
 */

:root {
  --palette-gray-50: #f8f9fc;
  --palette-gray-100: #f3f4f6;
  --palette-gray-200: #e5e7eb;
  --palette-gray-300: #d1d4db;
  --palette-gray-400: #9ca2af;
  --palette-gray-500: #6b7280;
  --palette-gray-600: #4b5363;
  --palette-gray-700: #3a414e;
  --palette-gray-800: #2c313a;
  --palette-gray-900: #181b20;
  --palette-gray-950: #0b0c0e;
  --palette-gray-a80: #171c26cc;
  --palette-gray-a60: #171c2699;
  --palette-gray-a40: #171c2666;
  --palette-gray-a20: #171c2633;
  --palette-gray-a10: #171c261a;
  --palette-gray-a05: #171c260d;
  --palette-green-50: #effaf5;
  --palette-green-100: #d9f2e5;
  --palette-green-200: #b4e6cd;
  --palette-green-300: #80d5ad;
  --palette-green-400: #39ce8d;
  --palette-green-500: #13ae70;
  --palette-green-600: #059460;
  --palette-green-700: #047851;
  --palette-green-800: #065f41;
  --palette-green-900: #064e36;
  --palette-green-950: #022c1e;
  --palette-green-a80: #059460cc;
  --palette-green-a60: #05946099;
  --palette-green-a40: #05946066;
  --palette-green-a20: #05946033;
  --palette-green-a10: #0594601a;
  --palette-green-a05: #0594600d;
  --palette-brand-50: #f5f6ff;
  --palette-brand-100: #ebedff;
  --palette-brand-200: #d7dbfe;
  --palette-brand-300: #b0b5fc;
  --palette-brand-400: #8185f8;
  --palette-brand-500: #5e59f3;
  --palette-brand-600: #5646e5;
  --palette-brand-700: #4738ca;
  --palette-brand-800: #3f30a3;
  --palette-brand-900: #392e81;
  --palette-brand-950: #211b4b;
  --palette-brand-a80: #4656e5cc;
  --palette-brand-a60: #4656e599;
  --palette-brand-a40: #4656e566;
  --palette-brand-a20: #4656e533;
  --palette-brand-a10: #4656e51a;
  --palette-brand-a05: #4656e50d;
  --palette-red-50: #fef2f2;
  --palette-red-100: #fee2e2;
  --palette-red-200: #fecaca;
  --palette-red-300: #fca5a5;
  --palette-red-400: #f87171;
  --palette-red-500: #ef4444;
  --palette-red-600: #dc2626;
  --palette-red-700: #b91c1c;
  --palette-red-800: #991b1b;
  --palette-red-900: #7f1d1d;
  --palette-red-950: #450a0a;
  --palette-red-a80: #ef4444cc;
  --palette-red-a60: #ef444499;
  --palette-red-a40: #ef444466;
  --palette-red-a20: #ef444433;
  --palette-red-a10: #ef44441a;
  --palette-red-a05: #ef44440d;
  --palette-amber-50: #fffbeb;
  --palette-amber-100: #fef3c7;
  --palette-amber-200: #fde68a;
  --palette-amber-300: #fcd34d;
  --palette-amber-400: #fbbf24;
  --palette-amber-500: #f59e0b;
  --palette-amber-600: #d97706;
  --palette-amber-700: #b45309;
  --palette-amber-800: #92400e;
  --palette-amber-900: #78350f;
  --palette-amber-950: #451a03;
  --palette-amber-a80: #f59e0bcc;
  --palette-amber-a60: #f59e0b99;
  --palette-amber-a40: #f59e0b66;
  --palette-amber-a20: #f59e0b33;
  --palette-amber-a10: #f59e0b1a;
  --palette-amber-a05: #f59e0b0d;
  --palette-white-a100: #ffffff;
  --palette-white-a10: #ffffff1a;
  --palette-white-a05: #ffffff0d;
  --palette-white-a20: #ffffff33;
  --palette-white-a40: #ffffff66;
  --palette-white-a60: #ffffff99;
  --palette-white-a80: #ffffffcc;
  --typography-font-family-default: Inter, "Inter", system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Helvetica Neue", "Noto Sans", "Droid Sans", Arial, sans-serif;
  --typography-font-weight-400: Regular;
  --typography-font-weight-500: Medium;
  --typography-font-weight-700: Bold;
  --typography-font-weight-800: Extra Bold;
  --typography-font-size-2xs: 0.625rem;
  --typography-font-size-xs: 0.75rem;
  --typography-font-size-sm: 0.875rem;
  --typography-font-size-md: 1rem;
  --typography-font-size-lg: 1.25rem;
  --typography-font-size-xl: 1.5rem;
  --typography-font-size-2xl: 2rem;
  --typography-font-size-3xl: 2.5rem;
  --typography-line-height-2xs-line: 0.875rem;
  --typography-line-height-xs-line: 1rem;
  --typography-line-height-sm-line: 1.25rem;
  --typography-line-height-md-line: 1.5rem;
  --typography-line-height-lg-line: 1.75rem;
  --typography-line-height-xl-line: 2rem;
  --typography-line-height-2xl-line: 2.75rem;
  --typography-line-height-3xl-line: 3.5rem;
  --typography-line-height-2xs-paragraph: 1rem;
  --typography-line-height-xs-paragraph: 1.25rem;
  --typography-line-height-sm-paragraph: 1.375rem;
  --typography-line-height-md-paragraph: 1.625rem;
  --typography-line-height-lg-paragraph: 2rem;
  --typography-line-height-xl-paragraph: 2.375rem;
  --typography-line-height-2xl-paragraph: 3.125rem;
  --typography-line-height-3xl-paragraph: 4rem;
  --typography-letter-spacing-default: 0rem;
  --utilities-border-radius-none: 0rem;
  --utilities-border-radius-sm: 0.125rem;
  --utilities-border-radius-md: 0.25rem;
  --utilities-border-radius-default: 0.5rem;
  --utilities-border-radius-lg: 0.75rem;
  --utilities-border-radius-xl: 1rem;
  --utilities-border-radius-2xl: 1.5rem;
  --utilities-border-radius-3xl: 1.75rem;
  --utilities-border-radius-full: 999px;
  --utilities-size-none: 0rem;
  --utilities-size-0-125: 0.125rem;
  --utilities-size-0-250: 0.25rem;
  --utilities-size-0-500: 0.5rem;
  --utilities-size-0-750: 0.75rem;
  --utilities-size-1-000: 1rem;
  --utilities-size-1-250: 1.25rem;
  --utilities-size-1-500: 1.5rem;
  --utilities-size-1-750: 1.75rem;
  --utilities-size-2-000: 2rem;
  --utilities-size-2-250: 2.25rem;
  --utilities-size-2-500: 2.5rem;
  --utilities-size-3-000: 3rem;
  --utilities-size-3-500: 3.5rem;
  --utilities-size-4-000: 4rem;
  --utilities-size-5-000: 5rem;
  --utilities-size-7-500: 7.5rem;
  --utilities-size-0-375: 0.375rem;
  --utilities-backdrop-blur-md: 1.25rem;
  --shadows-subtle: 0px 1px 2px 0px #171c260d;
  --shadows-default: 0px 0px 0px 0.5px #171c2605, 0px 1px 2px 0px #171c260d, 0px 2px 4px 0px #171c261a;
  --shadows-popover: 0px 0px 0px 1px #171c260d, 0px 4px 8px 0px #171c260d, 0px 8px 16px -2px #171c260d, 0px 12px 24px -4px #171c260d;
  --shadows-modal: 0px 80px 80px -8px #171c260d, 0px 48px 48px -8px #171c260d, 0px 24px 24px -4px #171c260d, 0px 12px 12px -2px #171c260d, 0px 6px 6px 0px #171c260d, 0px 2px 2px 0px #171c260d;
  --surface-default: #ffffff;
  --surface-pale: #f3f4f6;
  --surface-neutral: #9ca2af;
  --surface-accent: #5e59f3;
  --surface-accent-pale: #ebedff;
  --surface-accent-weak: #4656e599;
  --surface-accent-tint: #4656e51a;
  --surface-accent-tint-weak: #4656e50d;
  --surface-tint-weak: #171c260d;
  --surface-tint-medium: #171c261a;
  --surface-tint-strong: #171c2633;
  --surface-bright: #ffffff1a;
  --surface-inverted: #2c313a;
  --surface-rude: #4b5363;
  --surface-danger: #ef4444;
  --surface-danger-pale: #fef2f2;
  --surface-danger-tint: #ef44441a;
  --surface-success: #13ae70;
  --surface-success-pale: #effaf5;
  --surface-success-tint: #0594601a;
  --surface-warning: #f59e0b;
  --surface-warning-pale: #fef3c7;
  --surface-warning-tint: #f59e0b1a;
  --surface-hover-default-hover: #f5f6ff;
  --surface-hover-accent-hover: #5646e5;
  --surface-hover-neutral-hover: #6b7280;
  --surface-hover-rude-hover: #3a414e;
  --surface-hover-bright-hover: #ffffff33;
  --surface-hover-tint-weak-hover: #171c261a;
  --surface-hover-tint-medium-hover: #171c2633;
  --surface-hover-tint-strong-hover: #171c2666;
  --surface-hover-accent-weak-hover: #4656e5cc;
  --surface-hover-accent-tint-hover: #4656e533;
  --surface-hover-danger-hover: #dc2626;
  --surface-hover-danger-tint-hover: #ef444433;
  --surface-hover-success-hover: #059460;
  --surface-hover-success-tint-hover: #05946033;
  --surface-hover-warning-hover: #d97706;
  --surface-hover-warning-tint-hover: #f59e0b33;
  --content-primary: #2c313a;
  --content-primary-weak: #171c2666;
  --content-primary-muted: #171c2633;
  --content-secondary: #6b7280;
  --content-tertiary: #d1d4db;
  --content-inverted: #ffffff;
  --content-inverted-weak: #ffffff66;
  --content-inverted-muted: #ffffff33;
  --content-accent: #5e59f3;
  --content-accent-pale: #8185f8;
  --content-accent-weak: #4656e566;
  --content-danger: #ef4444;
  --content-danger-pale: #f87171;
  --content-danger-weak: #ef444466;
  --content-success: #13ae70;
  --content-success-pale: #39ce8d;
  --content-success-weak: #05946066;
  --content-warning: #f59e0b;
  --content-warning-pale: #fbbf24;
  --content-warning-weak: #f59e0b99;
  --content-hover-primary-hover: #5646e5;
  --content-hover-primary-weak-hover: #2c313a;
  --content-hover-secondary-hover: #5646e5;
  --content-hover-tertiary-hover: #9ca2af;
  --content-hover-inverted-weak-hover: #ffffff99;
  --content-hover-inverted-hover: #ffffff99;
  --content-hover-accent-hover: #5646e5;
  --content-hover-danger-hover: #dc2626;
  --content-hover-success-hover: #059460;
  --content-hover-warning-hover: #b45309;
  --overlay-weak: #171c2633;
  --overlay-medium: #171c2666;
  --overlay-strong: #171c26cc;
  --border-default: #171c261a;
  --border-default-inverted: #ffffff1a;
  --border-control-default: #171c2633;
  --border-control-inverted: #ffffff66;
  --border-rude: #2c313a;
  --border-rude-weak: #171c2666;
  --border-accent: #5e59f3;
  --border-accent-weak: #4656e533;
  --border-bright: #ffffff;
  --border-danger: #f87171;
  --border-danger-weak: #ef444433;
  --border-success: #13ae70;
  --border-success-weak: #05946033;
  --border-warning: #f59e0b;
  --border-warning-weak: #f59e0b33;
  --border-focus: #8185f8;
  --border-hover-default-hover: #171c2633;
  --border-hover-default-inverted-hover: #ffffff33;
  --border-hover-control-default-hover: #171c2699;
  --border-hover-control-inverted-hover: #ffffff99;
  --border-hover-accent-hover: #5646e5;
  --border-hover-accent-weak-hover: #4656e566;
  --border-hover-danger-weak-hover: #ef444466;
  --border-hover-success-weak-hover: #05946066;
  --border-hover-warning-weak-hover: #f59e0b66;
  --brand-primary: #5e59f3;
  --brand-light: #ffffff;
  --brand-dark: #2c313a;
  --brand-neutral-surface: #9ca2af;
  --brand-neutral-content: #6b7280;
  --brand-danger: #ef4444;
  --brand-success: #13ae70;
  --brand-warning: #f59e0b;
}
